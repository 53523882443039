<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar cliente
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form @submit.prevent="buscar()">
          <v-row class="mx-4 mt-0 mb-3">
            <v-col cols="6" sm="3" class="py-1">
              Apellido
              <v-text-field
                v-model.trim="apellido"
                hide-details
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              Nombre
              <v-text-field
                v-model.trim="nombre"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              Documento
              <v-text-field
                v-model.trim="documento"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
                :disabled="nombre == '' && apellido == '' && documento == ''"
                :loading="load"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          class="cebra"
          sort-by="apellido"
          :loading="load"
          :search="search"
          :items="cleintes"
          :headers="headers"
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex justify-end py-2 px-4" no-gutters>
              <v-col cols="6" sm="4" xl="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Seleccionar"
              small
              @click="$emit('selected', item); dialog = false"
            >
              fas fa-check
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              color="orange"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'

export default {
  data () {
    return {
      load: false,
      search: '',
      nombre: '',
      apellido: '',
      documento: '',
      cleintes: [],
      headers: [
        { text: 'Apellido', value: 'apellido' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Nº Documento', value: 'num_doc' },
        { text: 'CUIT', value: 'cuit' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async buscar () {
      this.cleintes = []
      
      this.load = true
      let result = await this.$store.dispatch('clientes/buscar_cliente', {
        nombre: this.nombre,
        apellido: this.apellido,
        documento: this.documento
      })
      this.load = false

      if (result.exito == 1) {
        this.cleintes = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    limpiar () {
      this.cleintes = []
      this.search = ''
      this.nombre = ''
      this.apellido = ''
      this.documento = ''
    }
  }
}
</script>